import React, { lazy, useState, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";

import "./App.css";

import { IsAuthenticatedContext } from "./components/IsAuthenticatedContext";
import { FirstSearchContext } from "./components/FirstSearchContext";
import { QueryMapContext } from "./components/QueryMapContext";
import { SearchContext } from "./components/SearchContext";
import { FacetContext } from "./components/FacetContext";
import { AttSearchContext } from "./components/AttSearchContext";
import { MapSearchContext } from "./components/MapSearchContext";
import { MapOffCanvasContext } from "./components/MapOffCanvasContext";

const Login = lazy(() => import("./components/Login"));
const Search = lazy(() => import("./components/Search"));
const AttSearch = lazy(() => import("./components/AttSearch"));
const TechMap = lazy(() => import("./components/TechMap"));
const Help = lazy(() => import("./components/Help"));
const User = lazy(() => import("./components/User"));
const Logout = lazy(() => import("./components/Logout"));
const PasswordReset = lazy(() => import("./components/PasswordReset"));
const PasswordResetConfirm = lazy(() => import("./components/PasswordResetConfirm"));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [firstSearch, setFirstSearch] = useState(true);
  const [queryMap, setQueryMap] = useState("");
  const [mapOffCanvas, setMapOffCanvas] = useState(false);

  const [searchParams, setSearchParams] = useState({
    query: "",
    min_appln_filing_year: undefined,
    max_appln_filing_year: undefined,
    ipr_types: [],
    publn_auths: [],
    applicant_names: [],
    tech_cls: "",
    ipcs: [],
    cpcs: [],
    nace2s: [],
    techn_fields: [],
    applicant_ctrys: [],
  });

  const [attSearchParams, setAttSearchParams] = useState({
    min_appln_filing_year: undefined,
    max_appln_filing_year: undefined,
    ipr_types: ["PI"],
    publn_auths: ["US"],
    applicant_names: [],
    tech_cls: "ipc",
    ipcs: [],
    cpcs: [],
    nace2s: [],
    techn_fields: [],
    applicant_ctrys: [],
  });

  const [mapSearchParams, setMapSearchParams] = useState({
    mapWords: [],
    mapApplicantNames: [],
  });

  const [facetData, setFacetData] = useState({isReady: false});

  const localAuth = localStorage.getItem("isAuth");

  return (
    <Container fluid>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <IsAuthenticatedContext.Provider
            value={{ isAuthenticated, setIsAuthenticated }}
          >
            <FirstSearchContext.Provider
              value={{ firstSearch, setFirstSearch }}
            >
              <FacetContext.Provider value={{ facetData, setFacetData }}>
                <QueryMapContext.Provider value={{ queryMap, setQueryMap }}>
                  <SearchContext.Provider
                    value={{ searchParams, setSearchParams }}
                  >
                    <AttSearchContext.Provider
                      value={{ attSearchParams, setAttSearchParams }}
                    >
                      <MapSearchContext.Provider
                        value={{ mapSearchParams, setMapSearchParams }}
                      >
                        <MapOffCanvasContext.Provider
                          value={{ mapOffCanvas, setMapOffCanvas }}
                        >
                          <Routes>
                            <Route index element={<Login />} />
			    <Route path="/password-reset" element={<PasswordReset />} />
			    <Route path="/password-reset-confirm/:param1/:param2/" element={<PasswordResetConfirm />} />
                            {(isAuthenticated || localAuth) && (
                              <>
                                <Route path="/search" element={<Search />} />
                                <Route
                                  path="/attsearch"
                                  element={<AttSearch />}
                                />
                                <Route path="/techmap" element={<TechMap />} />
                                <Route path="/help" element={<Help />} />
                                <Route path="/user" element={<User />} />
                                <Route path="/logout" element={<Logout />} />
                              </>
                            )}
                          </Routes>
                        </MapOffCanvasContext.Provider>
                      </MapSearchContext.Provider>
                    </AttSearchContext.Provider>
                  </SearchContext.Provider>
                </QueryMapContext.Provider>
              </FacetContext.Provider>
            </FirstSearchContext.Provider>
          </IsAuthenticatedContext.Provider>
        </Suspense>
      </Router>
    </Container>
  );
}

export default App;
